import { decodeJwt } from "jose";

type LocalStorageSettings = {
    locale?: string;
    lastSeen?: string;
};

export default defineNuxtRouteMiddleware(() => {
    let validSettings: { key: string; lastSeen: number | null } = {
        key: "",
        lastSeen: null,
    };

    const settingsKey = `master-administration-localSettings-settings`;
    const settings = useLocalStorage<string | undefined>(
        settingsKey,
        undefined,
    );

    if (!settings.value) {
        deleteAllEntries();
        return;
    }

    try {
        const settingsObj = parseSettings(settings.value);

        if (!settingsObj?.lastSeen) {
            deleteAllEntries();
            return;
        }

        const lastSeenTime = Number(settingsObj.lastSeen);

        if (isOlderThanOneWeek(lastSeenTime)) {
            updateActiveLastSeen();
        }

        checkAndDeleteInvalidToken();

        validSettings = {
            key: settingsKey,
            lastSeen: lastSeenTime,
        };
    } catch (error) {
        console.error(`Failed to parse settings: `, error);
        deleteAllEntries();
    }
});

/**
 * Parses the localStorage settings value and returns the parsed object.
 */
function parseSettings(settingsValue: string): LocalStorageSettings | null {
    try {
        return JSON.parse(settingsValue) as LocalStorageSettings;
    } catch (error) {
        console.error(`Error parsing settings:`, error);
        return null;
    }
}

/**
 * Deletes only the token-related localStorage entries.
 */
function deleteTokenEntries(): void {
    const token = useLocalStorage<string | undefined>(
        `master-administration-tokenStore-token`,
        undefined,
    );
    const refreshToken = useLocalStorage<string | undefined>(
        `master-administration-tokenStore-refreshToken`,
        undefined,
    );
    const refreshTokenTtl = useLocalStorage<string | undefined>(
        `master-administration-tokenStore-refreshTokenTtl`,
        undefined,
    );

    token.value = undefined;
    refreshToken.value = undefined;
    refreshTokenTtl.value = undefined;
}

/**
 * Deletes all localStorage entries (including settings).
 */
function deleteAllEntries(): void {
    deleteTokenEntries();

    const settings = useLocalStorage<string | undefined>(
        `master-administration-localSettings-settings`,
        undefined,
    );
    settings.value = undefined;
}

/**
 * Updates the lastSeen value.
 */
function updateActiveLastSeen(): void {
    const settings = useLocalStorage<string | undefined>(
        "master-administration-localSettings-settings",
        undefined,
    );

    if (settings.value) {
        try {
            const settingsObj = JSON.parse(
                settings.value,
            ) as LocalStorageSettings;
            settingsObj.lastSeen = Date.now().toString();
            settings.value = JSON.stringify(settingsObj);
        } catch (error) {
            console.error(
                `Error updating lastSeen for localSettings-settings`,
                error,
            );
        }
    }
}

/**
 * Checks if the JWT token is valid, and deletes token entries if invalid.
 */
function checkAndDeleteInvalidToken(): void {
    const token = useLocalStorage<string | undefined>(
        `master-administration-tokenStore-token`,
        undefined,
    );
    if (token.value && !isJwtTokenValid(token.value)) {
        deleteTokenEntries();
    }
}

/**
 * Helper function to check if the timestamp is older than one week.
 */
function isOlderThanOneWeek(timestampInMs: number): boolean {
    const oneWeekInMs = 7 * 24 * 60 * 60 * 1000;
    const currentTimeInMs = Date.now();
    return currentTimeInMs - timestampInMs > oneWeekInMs;
}

/**
 * Helper function to check if the JWT token is still valid.
 */
export function isJwtTokenValid(token: string): boolean {
    if (!token) return false;

    try {
        const payload = decodeJwt(token);
        if (!payload.exp) return false;

        const currentTime = Math.floor(Date.now() / 1000);
        return payload.exp > currentTime;
    } catch (error) {
        console.error("Invalid token:", error);
        return false;
    }
}
